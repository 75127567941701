@import "../../styles/base";

.SearchListEntry {
  a {
    text-decoration: none;
  }

  .SearchListEntryContent {
    h2,
    h3,
    p,
    li {
      font-size: 1.4rem;
      line-height: 1.4;

      @media #{$desktop-lg} {
        font-size: 1.6rem;
      }
    }

    padding: 0.75em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #cfd4d8;

    .SearchListEntryHeader {
      width: 90%;
      display: flex;
      flex-direction: column;
    }

    .SearchListEntryPublishedDate {
      color: color("dark", 0.5);
      font-weight: 500;
      flex: 0 0 auto;
      margin-bottom: 0.35em;
    }

    .LoadingIndicator {
      cursor: default;
    }
  }
}
