@import '../../styles/base';

.ReportFilterForm {
  padding-left: 1.2em;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: 100%;
  max-width: 100%;
  ::-moz-scrollbar{width: 10px;}
  scrollbar-width: thin !important;
  @media #{$upto-tablet-lg} {
    padding-left: 1.6em;
  }
}

// .SubtleScrollbar, .CategoryCheckboxList, .StocksCheckboxList, .CountriesCheckboxList {
  // /* width */
  // &::-webkit-scrollbar {
  //   width: 20px;
  // }
  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  // }
  /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: black;
  //   visibility: hidden;
  // }
  // &:hover {
  //   &::-webkit-scrollbar-thumb {
  //     visibility: visible;
  //   }
  // }
  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: inherit;
  // }

  // transition-property: overflow;
  // transition-duration: 1s;
  // transition-delay: 1s;
  // transition-timing-function: linear;
  // overflow: hidden;
  // overscroll-behavior: contain;
// }

.ReportFilterFormContent,
.SearchReportResults {
  width: 21em;
  // padding: 1em;
  padding: 1.5em 1em 1em 1em;
}

.SearchReportResults {
  padding-top: 0;
  padding-bottom: 0;

  &.hide {
    display: none;
  }

  .SearchReportClearButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .BaseButton {
      text-transform: uppercase;
      margin: 1em;
      width: 100%;
    }
  }
}

.ReportFilterFormContent {
  &.hide {
    display: none;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 700;

    @media #{$desktop} {
      font-size: 1.4rem;
    }
  }

  .BaseButton {
    background-color: transparent;
    color: color('blue');
    min-width: unset;
  }

  .ReportFilterByMonths .BaseButton {
    padding: 0;
  }

  .ReportByCategories, .ReportByProducts, .ReportByStocks, .ReportByCountries {
    .BaseButton {
      padding: 0;
    }
  }

  .ReportFilterByMonthsHeader {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    color: color('dark', .6);

    @media #{$desktop} {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;
    color: color('blue');
    font-size: 1.2rem;
    font-weight: 700;

    @media #{$desktop} {
      font-size: 1.4rem;
    }
  }
}

.ReportFilterDate {
  display: flex;
  align-items: center;

  @media #{$upto-phone-md} {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    margin: 0 .75rem;
    font-size: 1.2rem;

    @media #{$desktop} {
      font-size: 1.4rem;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    // -webkit-appearance: none;
    // opacity: 0;
  }


  input {
    width: 15rem;
    background-color: color('gray', .4);
    border: 2px solid color('dark', .12);
    font-size: 1.4rem;
    padding: .5em
  }
}

.ReportFilterByMonths {
  display: flex;
  flex-flow: wrap;

  @media #{$upto-phone-lg} {
    flex-direction: column;
    align-items: flex-start;

    >* {
      &, +* {
        padding: 0.2em 0 !important;
        margin: 0.2em 0;
      }
    }
  }

  @media #{$from-phone-lg} {
    justify-content: space-between;
  }
}

.ReportByCategories,
.ReportByProducts,
.ReportByCountries,
.ReportByStocks {
  padding: 1em 0;
  border-top: 1px dashed color('dark', 0.2);
  border-bottom: 1px dashed color('dark', 0.2);
}

.ReportByCountriesHeader,
.ReportByCategoriesHeader,
.ReportByProductsHeader,
.ReportByStocksHeader {
  display: flex;
  justify-content: space-between;

  h4 {
    user-select: none;
    display: flex;
    align-items: center;
    
    // &::before {
    //   margin-right: .5em;
    //   content: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1L5.5 5L9.5 1' stroke='%23033146' stroke-width='2'/%3E%3C/svg%3E%0A");
    // }

    &:hover {
      cursor: pointer;
    }
    svg {
      margin-top: -0.19em;
      margin-right: .5em;
    }
  }

  .ToggleUp {
    transform: rotate(180deg);
  }

}
// .toggleOpen {
//   &::after {
//     content: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.0014 5.99713L4.99854 1.99999L1.00141 6.00285' stroke='%230A1D2A' stroke-width='2'/%3E%3C/svg%3E%0A");
//   }
// }

.ReportByStocks, .ReportByCountries {
  // padding-top: .75em;
  border-top: none;
}

.FilterSearch {
  margin-bottom: 1em;
}

.ReportFilterFormContent {
  .CheckboxList {
    margin: 0;
    height: 100%;
    // overflow: auto;
    list-style: none;
    -webkit-overflow-scrolling: touch;
    margin-top: .72em;
    border-left: 1px solid color('blue',.2);
    padding-left: 1.5em;

    // max-height: 22rem;

    // @media #{$desktop} {
    //   max-height: 28rem;
    // }
    // @media #{$desktop-lg} {
    //   max-height: 43rem;
    // }

    > li {
      // margin-bottom: .72em;
      font-size: 1.2rem;
      font-weight: 500;
      
      // @media #{$desktop} {
      //   font-size: 1.4rem;
      //   margin-bottom: .92em;
      // }
      label {
        cursor: pointer;
      }
    }
  }

  .BaseCheckbox {
    margin-bottom: .72em;
    // font-size: 1.2rem;
    // font-weight: 500;
    @media #{$desktop} {
      font-size: 1.4rem;
      margin-bottom: .92em;
    }

    &.isSelected {
      .BaseCheckboxLabelMarker {
        color: color('white');
        background-color: color('dark');
      }
    }

    .BaseCheckboxLabelMarker {
      border: 1px solid color('dark');
      border-radius: unset;
      margin-right: .8em;
      width: 1em;
      height: 1em;

      @media #{$desktop} {
        width: 1.3em;
        height: 1.3em;
      }
    }
    .BaseCheckboxLabelContent {
      font-size: inherit;
    }
  }
}
.AnimateEntry {
  animation: pageContentBlockEntry .6s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}
@keyframes pageContentBlockEntry {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}