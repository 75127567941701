@import '../../styles/base';

.ReportIndexSidebar {
  position: relative;

  @media #{$upto-tablet-lg} {
    display: none;

    &.OpenFilterSideBar {
      display: block;
      position: fixed;
      top: $AppNavBarHeight;
      bottom: 0;
      width: auto;
      z-index: 10;
      min-width: 12em;
      background-color: color('white');

      .ReportFilterForm {
        position: unset;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .SearchReportResults, .ReportFilterFormContent {
          width: 21em;
        }

        @media #{$upto-phone-md} {
          .SearchReportResults, .ReportFilterFormContent {
            width: 14em;
          }
        }
      }
    }
  }
}